import React from "react";
import DescriptionItem from "../components/DescriptionItem";
import Fade from "react-reveal/Fade";

import Real from "../img/real.svg";
import Clear from "../img/clear.svg";
import Fresh from "../img/fresh.svg";
import Unique from "../img/unique.svg";
import Volume from "../img/volume.svg";

const About = () => {
  return (
    <div className="section">
      <section className="py-8 lg:py-20" id="about">
        <div className="container mx-auto">
          <h1 className="text-2xl lg:text-3xl mb-8 lg:mb-20 font-primary italic text-center">
            КЫМЫС
          </h1>
          <Fade left cascade>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 lg:mx-8 gap-[30px] max-w-sm mx-auto md:max-w-none md:mx-0">
              <DescriptionItem
                title="Настоящий"
                image={Real}
                text="в составе только кобылье молоко"
              ></DescriptionItem>
              <DescriptionItem
                title="Свежий"
                image={Fresh}
                text="Ограниченное количество и регулярно обновляемые поставки"
              ></DescriptionItem>
              <DescriptionItem
                title="Уникальный"
                image={Unique}
                text="РОДОМ ИЗ КАТОН-КАРАГАЙСКОГО РАЙОНА"
              ></DescriptionItem>
              <DescriptionItem
                title="Чистый"
                image={Clear}
                text="Изготавливается традиционным методом под руководством фермера."
              ></DescriptionItem>
              <DescriptionItem
                title="Объемный"
                image={Volume}
                text="Сливочный вкус с оттенками свежего курта и характерной кислинкой. Аромат свежих трав, меловых нот и ряженки"
              ></DescriptionItem>
            </div>
          </Fade>
        </div>
      </section>
    </div>
  );
};

export default About;
