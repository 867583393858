import React, { useState } from "react";

import Logo from "../img/logo.svg";
// import { Link } from "react-router-dom";

const Hero = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <section className="h-[900px] bg-hero bg-no-repeat bg-cover bg-top py-20">
      <div className="container mx-auto flex justify-around h-full">
        {/* text */}
        <div className="flex flex-col justify-center items-center">
          <img className="h-43 w-43 object-top" src={Logo} alt="" />
          <button className="bg-[#D36257] ease-in duration-300 hover:bg-transparent hover:border-[1px] text-white w-2/5 font-bold py-2 px-4 my-10 rounded inline-flex justify-center items-center">
            <a href="#shop" onClick={() => setActiveNav("#shop")}>
              Заказать
            </a>
          </button>
          {/* <div className="font-semibold flex items-center uppercase">
            <div className="w-10 h-[2px] mr-3 bg-cyan-700"></div>Hot Trend
          </div> */}
          {/* <h1 className="uppercase text-[55px] md:text-[70px] leading-[1.1] font-semibold mb-4">Fresh Fashion Finds<br />
          <span className="font-light">new collection</span></h1>
          <Link to={'/'} className='self-start uppercase font-semibold border-b-2 border-primary'>Discover More</Link> */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
