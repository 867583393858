import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0002 10.999H22.0002C22.0002 5.869 18.1272 2 12.9902 2V4C17.0522 4 20.0002 6.943 20.0002 10.999Z"
        fill="white"
      />
      <path
        d="M12.9998 8.00024C15.1028 8.00024 15.9998 8.89724 15.9998 11.0002H17.9998C17.9998 7.77524 16.2248 6.00024 12.9998 6.00024V8.00024ZM16.4218 13.4432C16.2296 13.2686 15.9771 13.1754 15.7176 13.1835C15.4581 13.1915 15.2118 13.3001 15.0308 13.4862L12.6378 15.9472C12.0618 15.8372 10.9038 15.4762 9.71179 14.2872C8.51979 13.0942 8.15879 11.9332 8.05179 11.3612L10.5108 8.96724C10.6972 8.78637 10.8059 8.54006 10.814 8.28045C10.822 8.02083 10.7287 7.76828 10.5538 7.57624L6.85879 3.51324C6.68384 3.3206 6.44067 3.20374 6.18095 3.1875C5.92122 3.17125 5.66539 3.2569 5.46779 3.42624L3.29779 5.28724C3.1249 5.46075 3.02171 5.69169 3.00779 5.93624C2.99279 6.18624 2.70679 12.1082 7.29879 16.7022C11.3048 20.7072 16.3228 21.0002 17.7048 21.0002C17.9068 21.0002 18.0308 20.9942 18.0638 20.9922C18.3083 20.9786 18.5391 20.8749 18.7118 20.7012L20.5718 18.5302C20.7413 18.3328 20.8271 18.077 20.811 17.8173C20.795 17.5576 20.6783 17.3143 20.4858 17.1392L16.4218 13.4432Z"
        fill="white"
      />
    </svg>
  );
};

export default PhoneIcon;
