import React, { useContext } from "react";

import { ProductContext } from "../contexts/ProductContext";
import Product from "../components/Product";

import Hero from "../components/Hero";
import About from "./About";
import Composition from "./Composition";
import Katon from "./Katon";
import Citation from "./Citation";
import Delivery from "./Delivery";
import Shop from "./Shop";
import Contact from "./Contact";
import Partners from "../Partners";

import LogoKz from "../img/logotextkz.svg";
import LogoSaf from "../img/logo-saf.svg";
import BottleDrawing from "../img/bottle_drawing.svg";

const Home = () => {
  // get products from product context

  // get only men's and women's clothing category
  // const filteredProducts = products.filter((item) => {
  //   return (
  //     item.category === "men's clothing" || item.category === "women's clothing" || item.category === "jewelery"
  //   );
  // });

  return (
    <div className="section">
      <div className="bg-black text-white">
        <Hero />
        <About />
        <Composition image={LogoKz} />
        <Katon />
        <Partners />

        <Shop />
        {/* 
        <Delivery /> */}
        <Contact />
        <Citation />
      </div>
    </div>
  );
};

export default Home;
