import React, { createContext, useState, useEffect } from "react";
import Bottle from "../img/kymyz_black_white.JPG";

export const ProductContext = createContext();

const ProductProvider = ({ children }) => {
  // products state
  const [products, setProducts] = useState([]);
  // fetch products
  useEffect(() => {
    const fetchProducts = async () => {
      // const response = await fetch("https://fakestoreapi.com/products");
      // const data = await response.json();

      const data = [
        {
          id: 1,
          title: "Кумыс 1л",
          description: "1 литр свежего Кумыса",
          price: "4400",
          image: Bottle,
          rating: { rate: 3.9, count: 120 },
          category: "Кумыс",
        },
      ];
      setProducts(data);
    };
    fetchProducts();
  }, []);

  return (
    <ProductContext.Provider value={{ products }}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
