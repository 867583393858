import React, { useState, useContext } from "react";

import { CartContext } from "../contexts/CartContext";
import CartItem from "../components/CartItem";
import { Link } from "react-router-dom";

import { IoMdAdd, IoMdClose, IoMdRemove } from "react-icons/io";

function OrderForm({ setIsOpenForm }) {
  const { cart, clearCart, itemAmount, total } = useContext(CartContext);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [address, setAddress] = useState("");
  const [isDelivery, setIsDelivery] = useState("");
  const [message, setMessage] = useState("");

  function handleSubmit(event) {
    if (
      name.length === 0 ||
      phone.length === 0 ||
      address.length === 0 ||
      date.length === 0 ||
      time.length === 0
    ) {
      console.log("Not sending request");
    } else {
      const data = {
        fullname: name,
        phone: phone,
        date: date,
        time: time,
        address: address,
        isDelivery: isDelivery,
        itemAmount: itemAmount,
        total: total,
        message: message,
      };
      fetch("https://safkymyz.kz/order.php", {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify(data),
      })
        .then((response) => {
          return response.text();
        })
        .then((responseText) => {
          if (responseText === "Message sent successfully!") {
            alert(
              "Спасибо за обращение в SafQYMYZ🤎. С вами свяжутся в скором времени для подтверждения заказа! Благодарим!"
            );
          }
        });
    }
    // if (address.length === 0) {
    //   alert("Введите адрес");
    // }
  }

  const onOptionChange = (e) => {
    setIsDelivery(e.target.value);
  };

  return (
    <div className=" lg:fixed fixed bottom-0 w-full h-auto flex justify-center items-center bg-black bg-opacity-30">
      <div className="flex justify-center items-center">
        <form className="bg-white fixed xl:fixed bottom-0 w-full lg:h-[510px] top-24 max-w-lg block p-6 rounded-lg overflow-y-auto overflow-x-hidden">
          <div className="absolute top-0 right-0">
            <button
              onClick={() => setIsOpenForm(false)}
              type="button"
              className="p-2 box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
              data-te-modal-dismiss
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Имя
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="ФИО"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-phone-number"
              >
                Номер телефона
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-phone-number"
                type="phone"
                placeholder="+7(___)___-__-__"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex mb-4">
            <div className="flex items-center  rounded dark:border-gray-700">
              <input
                onChange={onOptionChange}
                checked={isDelivery === "delivery"}
                value="delivery"
                type="radio"
                className="w-4 h-4 text-blue-600 bg-gray-100 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label className="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Доставка
              </label>
            </div>
            <div className="flex items-center pl-4 rounded dark:border-gray-700">
              <input
                onChange={onOptionChange}
                checked={isDelivery === "pickup"}
                value="pickup"
                type="radio"
                className="w-4 h-4 text-blue-600 bg-gray-100 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="location"
                className="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Самовывоз
              </label>
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-2">
            {isDelivery === "delivery" && (
              <div className="w-full md:w-1/3 px-3 mb-2 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-city"
                >
                  Адрес
                </label>
                <input
                  className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-city"
                  type="text"
                  placeholder="Улица, № дома, № квартиры"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            )}
            {isDelivery === "pickup" && (
              <div className="w-full md:w-1/3 px-3 mb-2 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-city"
                >
                  Адрес
                </label>
                <select
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name=""
                  id="location"
                >
                  <option value="кафе LAGOM, Мәңгілік ел 40">
                    кафе LAGOM, Мәңгілік ел 40
                  </option>
                  <option value="Rumi, Mega Silkway">Rumi, Mega Silkway</option>
                  <option value="Caviar House, ТРЦ Сарыарка">
                    Caviar House, ТРЦ Сарыарка
                  </option>
                  <option value="Bismart, Кошкарбаева 10 ">
                    Bismart, Кошкарбаева 10
                  </option>
                  <option value="Craft Cheese, Алихан Бокейхан, 42">
                    Craft Cheese, Алихан Бокейхан, 42
                  </option>
                </select>
              </div>
            )}

            <div className="w-full md:w-1/3 px-3 mb-2 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-date"
              >
                Дата
              </label>

              <div className="relative">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-date"
                  type="date"
                  placeholder="01/07/2023"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="w-full md:w-1/3 px-3 mb-2 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-time"
              >
                Время
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-time"
                type="time"
                placeholder="14:00"
                min="09:00"
                max="21:00"
                required
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-2 overflow-y-auto overflow-x-hidden w-full">
            {cart.map((item) => {
              return <CartItem item={item} key={item.id} />;
            })}
          </div>
          <div className="w-full mt-6">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              name="Сообщение"
              htmlFor="message"
            >
              Комментарий к заказу
            </label>
            <textarea
              id="text"
              rows="4"
              className="appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 w-full p-2.5"
              placeholder="Оставьте сообщение..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>

          <button
            onClick={function () {
              handleSubmit();
              clearCart();
            }}
            className="mt-4 bg-primary flex p-3 justify-center items-center text-white w-1/2 font-medium"
          >
            Оформить заказ
          </button>
        </form>
      </div>
    </div>
  );
}

export default OrderForm;
