import React, { useState } from "react";

function PartnersForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [text, setText] = useState("");

  //   state = {
  //     value: "",
  //     email: "",
  //     company: "",
  //     text: "",
  //     name: "",
  //   };

  const handleSubmit = async () => {
    if (name.length === 0) {
      alert("Введите ваше имя");
    }

    if (company.length === 0) {
      alert("Введите название компании ");
    }
    if (email.length === 0) {
      alert("Введите вашу почту");
    }

    const data = {
      fullname: name,
      email: email,
      company: company,
      message: text,
    };

    const response = await fetch("https://safkymyz.kz/partner.php", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.text();
      })
      .then((responseText) => {
        if (responseText === "Message sent successfully!") {
          alert(
            "Ваша форма отправлена! Спасибо за заявку, с вами свяжутся в скорейшем времени! Благодарим!"
          );
        }
      });

    // event.preventDefault();

    // const value = this.state.value;
    // const email = this.state.email;
    // const company = this.state.company;
    // const text = this.state.text;
    // const name = this.state.name;
    // console.log(email, company, text, name);
  };

  //   getValue = (event) => {
  //     const name = this.name.value;
  //     const email = this.email.value;
  //     const company = this.company.value;
  //     const text = this.text.value;
  //     this.setState({ email: email, company: company, text: text, name: name });
  //   };

  return (
    <form method="post" action="#" className="m-4 space-y-8">
      <div className="grid grid-col-1 lg:grid-cols-2">
        <div>
          <label
            className="text-black block mb-2 text-sm font-medium dark:text-gray-300"
            htmlFor="name"
          >
            Имя Фамилия
          </label>
          <input
            className="text-gray-900 text-sm border-b block w-full p-2.5"
            type="text"
            id="name"
            placeholder="Ваше Имя "
            value={name}
            onChange={(e) => setName(e.target.value)}
            //   ref={(input) => (this.name = input)}
            required
          />
        </div>

        <div className="lg:mx-10 mt-8 lg:mt-0 w-full">
          <label
            className="text-black block mb-2 text-sm font-medium dark:text-gray-300"
            htmlFor="email"
          >
            Ваша почта
          </label>
          <input
            className="text-gray-900 text-sm border-b block w-full p-2.5"
            type="email"
            id="email"
            placeholder="name@flowbite.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            //   ref={(input) => (this.email = input)}
            required
          />
        </div>
      </div>

      <div>
        <label
          className="text-black block mb-2 text-sm font-medium dark:text-gray-300"
          name="Компания"
          htmlFor="company"
        >
          Компания
        </label>
        <input
          className="text-gray-900 text-sm border-b block w-full p-2.5"
          type="text"
          id="company"
          placeholder="Введите название вашей компании"
          required
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          //   ref={(input) => (this.company = input)}
        />
      </div>
      <div className="sm:col-span-2">
        <label
          className="text-black block mb-2 text-sm font-medium dark:text-gray-300"
          name="Сообщение"
          htmlFor="message"
        >
          Сообщение
        </label>
        <textarea
          id="text"
          rows="4"
          className="text-gray-900 text-sm border-b block w-full p-2.5"
          placeholder="Оставьте сообщение..."
          value={text}
          onChange={(e) => setText(e.target.value)}
          //   ref={(input) => (this.text = input)}
        ></textarea>
      </div>
      <button
        onClick={handleSubmit}
        type="button"
        name="send"
        id="send"
        value="SEND"
        className="text-white py-3 px-8 text-sm font-medium text-center rounded-lg bg-black sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 "
      >
        Отправить
      </button>
    </form>
  );
}

export default PartnersForm;
