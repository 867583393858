import React from "react";

const DescriptionItem = (props) => {
  return (
    <section>
      <h3 className="text-xl text-center font-primary uppercase align-text-bottom text-beige ">
        {props.title}
      </h3>
      <img
        className="hover:scale-110 transition duration-300 rounded-lg py-2 lg:py-6"
        src={props.image}
      ></img>
      <h4 className="text-center font-primary md lg:text-lg uppercase">
        {props.text}
      </h4>
    </section>
  );
};

export default DescriptionItem;
