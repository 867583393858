import React from "react";
import { Slide } from "react-reveal";

const Citation = () => {
  return (
    <section className="bg-wood h-[400px] bg-no-repeat bg-top py-10 md:py-20 md:text-2xl lg:py-20 flex justify-center bg-blend-black">
      <Slide bottom>
        <p className="text-lg lg:text-4xl w-2/3 font-primary italic">
          " Привыкнув к кумысу, поневоле предпочитаешь его всем без исключения
          напиткам. Он охлаждает, утоляет одновременно голод и жажду и придает
          особую бодрость, никогда не переполняя и не обременяя желудок." -
          В.Даль(1849г.)
        </p>
      </Slide>
    </section>
  );
};

export default Citation;
