import React from "react";
import { Fade } from "react-reveal";

import BottleDrawing from "../img/bottle_drawing.svg";
import kese7 from "../img/KESE7.svg";
import kese3 from "../img/3kese.svg";

const Composition = (props) => {
  return (
    <div className="section">
      <section>
        <div className="flex md:m-10 lg:m-10 xl:m-10">
          <div className="h-[1px] bg-white w-full px-40"></div>
        </div>
        <div className="flex justify-center">
          <img
            className="h-12 mt-10 md:h-24 lg:h-30 xl:h-30 2xl:h-30"
            src={props.image}
          ></img>
        </div>
        <div className="place-items-center items-center grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-[350px_minmax(400px,_1fr)_650px] content-center my-10">
          <Fade left>
            <img
              className="hidden lg:block md:block xl:block 2xl:block w-20 mx-8 md:mx-16 lg:mx-16 md:w-24 lg:w-[170px]"
              src={BottleDrawing}
            ></img>
          </Fade>
          <Fade left>
            <div className="text-center lg:text-left font-secondary lg:py-16 px-4 lg:px-none italic w-full lg:text-3xl">
              <span className="font-primary">1-1,5% жирности</span>
              <br />
              <br />
              <span>
                Состав:{" "}
                <span className="font-primary">
                  натуральное кобылье молоко, закваска
                </span>
              </span>
              <br />
              <br />
              <p>
                Дата изготовления: смотрите на упаковке. Хранить при +4°C 4
                месяца. Правила применения: охладите бутылку, аккуратно
                взболтайте, осторожно откройте и наслаждайтесь вкусом настоящего
                кумыса.
              </p>
            </div>
          </Fade>
          <Fade right>
            <img
              className="w-56 lg:w-1/2 xl:mx-30 lg:mx-24 md:mx-18 m-10"
              src={kese7}
              alt=""
            />
          </Fade>
        </div>
      </section>
    </div>
  );
};

export default Composition;
