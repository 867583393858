import React, { useContext, useEffect, useState } from "react";
import { SidebarContext } from "../contexts/SidebarContext";
import { CartContext } from "../contexts/CartContext";
import { ProductContext } from "../contexts/ProductContext";
import { Link } from "react-router-dom";
import CartImage from "../img/cart.png";
import Nav from "./Nav";

const Header = () => {
  // header state
  const [isActive, setIsActive] = useState(false);

  const { isOpen, setIsOpen } = useContext(SidebarContext);
  const { itemAmount } = useContext(CartContext);

  // event listener
  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    });
  });

  return (
    <header
      className={`${
        isActive ? "bg-black py-4 shadow-md" : "bg-none py-6"
      } fixed w-full z-10 lg:px-8 transition-all`}
    >
      <div className="container mx-auto flex items-center justify-between h-full">
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="cursor-pointer flex relative"
        >
          <img src={CartImage} className="text-2xl"></img>
          <div
            className={`absolute -right-3 -bottom-2 text-[12px] w-[18px] h-[18px] text-white rounded-full flex justify-center items-center ${
              itemAmount > 0 ? "bg-red" : "bg-none"
            }`}
          >
            {`${itemAmount ? itemAmount : ""}`}
          </div>
        </div>
        <div className="sm:ml-6 sm:block">
          <div className="flex space-x-4 text-lg">
            <Nav />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
