import React, { useState } from "react";

import HamburgerIcon from "../img/icons/HamburgerIcon";
import CloseIcon from "../img/icons/CloseIcon";

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeNav, setActiveNav] = useState("#");
  return (
    <>
      <nav className="flex items-center justify-between ">
        <div className="block lg:hidden sm:block md:block">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
          >
            <HamburgerIcon
              className={`fill-white h-5 w-5 ${isOpen ? "hidden" : "block"}`}
            />
            <CloseIcon
              className={`fill-white h-5 w-5 ${isOpen ? "block" : "hidden"}`}
            />
          </button>
        </div>
        <div
          className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${
            isOpen ? "block" : "hidden"
          }`}
        >
          <div className="text-sm lg:text-xl lg:flex-grow">
            <a
              href="#about"
              onClick={() => setActiveNav("#about")}
              className="text-gray-300 rounded-md px-3 lg:py-2"
            >
              О Кумысе
            </a>
            <a
              href="#shop"
              onClick={() => setActiveNav("#shop")}
              className="block mt-2 lg:inline-block lg:mt-0 text-white-200 mr-4 text-gray-300 transition duration-0 hover:duration-500 ease-in hover:text-white rounded-md px-3 lg:py-2"
            >
              Магазин
            </a>
            <a
              href="#katon"
              onClick={() => setActiveNav("#katon")}
              className="block mt-2 lg:inline-block lg:mt-0 text-white-200 mr-4 text-gray-300 transition duration-0 hover:duration-500 ease-in hover:text-white rounded-md px-3 lg:py-2"
            >
              Катон-Карагай
            </a>
            <a
              href="#contact"
              onClick={() => setActiveNav("#contact")}
              className="block mt-2 lg:inline-block lg:mt-0 text-white-200 mr-4 text-gray-300 transition duration-0 hover:duration-500 ease-in hover:text-white rounded-md px-3 lg:py-2"
            >
              Партнерам
            </a>
            <a
              href="#contact"
              onClick={() => setActiveNav("#contact")}
              className="block mt-2 lg:inline-block lg:mt-0 text-white-200 mr-4 text-gray-300 transition duration-0 hover:duration-500 ease-in hover:text-white rounded-md px-3 lg:py-2"
            >
              Контакты
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
