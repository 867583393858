import React, { useContext } from "react";

import { ProductContext } from "../contexts/ProductContext";
import Product from "../components/Product";

import LogoSaf from "../img/logo-saf.svg";

const Shop = () => {
  const { products } = useContext(ProductContext);

  return (
    <section className="h-[850px] py-20" id="shop">
      <div className="container mx-auto">
        <div className="flex justify-center">
          <img className="w-50 mb-10" src={LogoSaf} alt="" />
        </div>

        <h1 className="font-secondary italic text-lg lg:text-3xl mb-16 text-center">
          Элитный кымыс, чтобы наслаждаться в кругу близких
        </h1>
        <div className="flex justify-center w-full gap-[30px] content-center max-w-sm mx-auto md:max-w-none md:mx-0">
          {products.map((product) => {
            return <Product product={product} key={product.id} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default Shop;
