import React from "react";
import Caviar_House from "../src/img/Caviar-House.svg";
import Lagom from "../src/img/Lagom.svg";
import Rumi from "../src/img/Rumi.svg";
import BisMart from "../src/img/BisMart.svg";
import CraftCheese from "../src/img/CraftCheese.jpg";

const Partners = () => {
  return (
    <section id="partners">
      <h1 className="text-2xl pt-20 uppercase lg:text-3xl mb-8 lg:mb-10 font-primary italic text-center">
        Где приобрести SAF Qymyz?
      </h1>
      {/* <span className="white">
        <a href="">Смотреть на карте</a>
      </span> */}
      {/* <iframe
        id="map_956867614"
        frameborder="0"
        width="100%"
        height="600px"
        sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"
      ></iframe> */}

      <div class="p-6 lg:p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5">
        <div class="hover:scale-110 transition duration-300 rounded bg-white lg:mx-6 overflow-hidden shadow-lg">
          <div className="flex justify-center pt-4 h-[150px]">
            <img class="w-36" src={Rumi} alt="Mountain" />
          </div>
          {/* <div class="px-6 py-4">
            <div class="font-bold text-xl mb-2 text-black">Rumi</div>
          </div> */}
          <div class="px-6 pt-4 pb-2 flex justify-center">
            <span class="inline-block font-primary rounded-full px-3 py-1 text-sm font-semibold text-black mr-2 mb-2">
              Mega Silkway - Кабанбай батыра, 62
            </span>
          </div>
        </div>

        <div class="hover:scale-110 transition duration-300 rounded bg-white lg:mx-6 overflow-hidden shadow-lg">
          <div className="flex justify-center pt-4 h-[150px]">
            <img class="w-36 " src={Caviar_House} alt="Forest" />
          </div>
          <div class="px-6 pt-4 pb-2 flex justify-center">
            <span class="inline-block font-primary rounded-full px-3 py-1 text-sm font-semibold text-black mr-2 mb-2">
              ТРЦ Сарыарка - Туран, 24
            </span>
          </div>
        </div>

        <div class="hover:scale-110 transition duration-300 rounded bg-white lg:mx-6 overflow-hidden shadow-lg ">
          <div className="flex justify-center pt-4 h-[150px]">
            <img class="w-36" src={Lagom} alt="River" />
          </div>
          <div class="px-6 pt-4 pb-2 flex justify-center">
            <span class="bottom inline-block font-primary rounded-full px-3 py-1 text-sm font-semibold text-black mr-2 mb-2">
              Мангилик Ел, 40
            </span>
          </div>
        </div>

        <div class="hover:scale-110 transition duration-300 rounded bg-white lg:mx-6 overflow-hidden shadow-lg ">
          <div className="flex justify-center pt-4 h-[150px]">
            <img class="w-36" src={BisMart} alt="River" />
          </div>
          <div class="px-6 pt-4 pb-2 flex justify-center">
            <span class="bottom inline-block font-primary rounded-full px-3 py-1 text-sm font-semibold text-black mr-2 mb-2">
              Кошкарбаева, 10
            </span>
          </div>
        </div>

        <div class="hover:scale-110 transition duration-300 rounded bg-white lg:mx-6 overflow-hidden shadow-lg ">
          <div className="flex justify-center pt-4 h-[150px]">
            <img class="w-36" src={CraftCheese} alt="CreaftCheese" />
          </div>
          <div class="px-6 pt-4 pb-2 flex justify-center">
            <span class="bottom inline-block font-primary rounded-full px-3 py-1 text-sm font-semibold text-black mr-2 mb-2">
              Алихан Бокейхан, 42
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
