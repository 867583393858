import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import ProductDetails from "./pages/ProductDetails";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Composition from "./pages/Composition";
import Katon from "./pages/Katon";
import Contact from "./pages/Contact";
import Partners from "./Partners";
import ThanksPage from "./pages/ThanksPage";

const App = () => {
  return (
    <div id="fullpage" className="overflow-hidden">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/composition" element={<Composition />}></Route>
          <Route path="/katon" element={<Katon />}></Route>
          <Route path="/product/:id" element={<ProductDetails />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/partners" element={<Partners />}></Route>
          <Route path="/thanks" element={<ThanksPage />}></Route>
          <Route path="/partner" element={<Contact />}></Route>
        </Routes>
        <Sidebar />
        <Footer />
      </Router>
    </div>
  );
};

export default App;
