import React from "react";
import { Zoom } from "react-reveal";
import { Slide } from "react-reveal";

const Katon = () => {
  return (
    <div className="section">
      <section
        className="h-[900px] lg:h-[800px] bg-katon bg-no-repeat bg-cover py-20"
        id="katon"
      >
        <Slide left cascade>
          <div className="container mx-auto lg:mx-20 mt-20 h-full">
            <h1 className="italic font-primary uppercase text-4xl md:text-4xl lg:text-5xl xl:text-5xl">
              Катон-Карагайский <br className="h-10" /> Район
            </h1>
            <div className="flex my-32">
              <div className="h-100 bg-white w-[2px] mr-2"></div>
              <span className="font-primary lg:text-4xl text-2xl italic">
                Кумыс производится небольшим хозяйством в Восточно- <br />{" "}
                Казахстансой области у подножия Алтайских гор
              </span>
            </div>
            <div className="flex my-32">
              <div className="h-100 bg-white w-[2px] mr-2"></div>
              <span className="font-primary lg:text-4xl text-2xl italic">
                Пастбище для животных находится на 1000 м. над уровнем моря{" "}
                <br /> Район богат разнотравьем, особенно – лекарственными
                травами.
              </span>
            </div>
          </div>
        </Slide>
      </section>
    </div>
  );
};

export default Katon;
