import Nav from "./Nav";
import React, { useState } from "react";
import Logo from "../img/logo-saf.svg";

const Footer = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <footer className="bg-black">
      <div className="container w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="block justify-center sm:flex sm:items-center sm:justify-between">
          <a
            href="https://safkymyz.kz/"
            className="flex items-center mb-4 sm:mb-0"
          >
            <img src={Logo} className="h-24 mr-3" alt="SafKymyz Logo" />
          </a>

          <ul className="flex flex-wrap justify-center text-center items-center mb-6 text-sm lg:text-lg font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <a
                href="#about"
                onClick={() => setActiveNav("#about")}
                className="mr-4 hover:underline md:mr-6 block mt-2 lg:inline-block lg:mt-0 text-white-200 lg:mr-4 text-gray-300 transition duration-0 hover:duration-500 ease-in hover:text-white rounded-md lg:py-2"
              >
                О Кумысе
              </a>
            </li>
            <li>
              <a
                href="#shop"
                onClick={() => setActiveNav("#shop")}
                className=" mr-4 hover:underline md:mr-6 block mt-2 lg:inline-block lg:mt-0 text-white-200 lg:mr-4 text-gray-300 transition duration-0 hover:duration-500 ease-in hover:text-white rounded-md lg:py-2"
              >
                Магазин
              </a>
            </li>
            <li>
              <a
                href="#katon"
                onClick={() => setActiveNav("#katon")}
                className="mr-4 hover:underline md:mr-6 block mt-2 lg:inline-block lg:mt-0 text-white-200 lg:mr-4 text-gray-300 transition duration-0 hover:duration-500 ease-in hover:text-white rounded-md lg:py-2"
              >
                Катон-Карагай
              </a>
            </li>
            <li>
              <a
                href="#partner"
                onClick={() => setActiveNav("#partner")}
                className=" mr-4 hover:underline md:mr-6 block mt-2 lg:inline-block lg:mt-0 text-white-200 lg:mr-4 text-gray-300 transition duration-0 hover:duration-500 ease-in hover:text-white rounded-md lg:py-2"
              >
                Партнерам
              </a>
            </li>
            <li>
              <a
                href="#contact"
                onClick={() => setActiveNav("#contact")}
                className=" mr-4 hover:underline md:mr-6 block mt-2 lg:inline-block lg:mt-0 text-white-200 lg:mr-4 text-gray-300 transition duration-0 hover:duration-500 ease-in hover:text-white rounded-md px-3 lg:py-2"
              >
                Контакты
              </a>
            </li>
          </ul>
        </div>
        {/* <div>
          <p className="text-white text-center">
            Copyright SafKymyz Inc. All rights reserved.
          </p>
        </div> */}
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2023{" "}
          <a
            href="https://safkymyz.kz"
            className="text-[#D36257] hover:underline"
          >
            Qatonönimder
          </a>
          . Все права защищены.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
