import React from "react";

const ThanksPage = () => {
  return (
    <div id="thanks">
      <p>
        "Спасибо за обращение в SafQYMYZ🤎. С вами свяжутся в скором времени для
        подтверждения заказа! Благодарим!"
      </p>
    </div>
  );
};

export default ThanksPage;
