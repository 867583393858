import React from "react";
import EmailIcon from "../img/icons/EmailIcon";
import LocationIcon from "../img/icons/LocationIcon";
import PhoneIcon from "../img/icons/PhoneIcon";
import InstagramIcon from "../img/icons/InstagramIcon";
import TiktokIcon from "../img/icons/TiktokIcon";
import PartnersForm from "../components/PartnersForm";

const Contact = () => {
  return (
    <div className="flex justify-center mt-10">
      <section
        className="bg-[#ffffff] lg:rounded-lg dark:bg-gray-900 font-secondary w-full mb-20 lg:mx-20 max-w-screen-lg "
        id="contact"
      >
        <div className="flex flex-wrap">
          <div className="bg-black lg:rounded-lg text-center lg:w-1/3 w-full lg:m-4 lg:px-8 shrink-0 grow-0 basis-auto md:mb-0 px-2.5">
            <h2 className="mb-8 text-3xl font-bold my-8 lg:text-left">
              Наши контакты
            </h2>
            <div className="flex lg:my-8">
              <LocationIcon />
              <p className="mx-4 mb-2 text-white">Астана, 010000, Казахстан</p>
            </div>
            <div className="flex lg:my-8">
              <PhoneIcon />
              <p className="mx-4 mb-2 text-white">+7 702 685 3032</p>
            </div>
            <div className="flex lg:my-8">
              <EmailIcon />
              <p className="mx-4 mb-2 text-white">elaman@safkymyz.kz</p>
            </div>
            <div className="flex inset-x-0 bottom-0 ">
              <div className="rounded-full bg-[#000000] hover:bg-[#1B1B1B] m-4">
                <a href="https://www.instagram.com/safqymyz/">
                  <InstagramIcon className="m-2 " />
                </a>
              </div>
              <div className="rounded-full bg-[#000000] hover:bg-[#1B1B1B] m-4">
                <a href="https://tiktok.com/">
                  <TiktokIcon className="m-2 " />
                </a>
              </div>
            </div>
          </div>
          <div className="w-full font-secondary shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:px-4 max-w-md">
            <p className="my-8 mx-4 text-lg text-black w-full sm:text-xl">
              Если хотите видеть наш кумыс в своем ресторане, оставьте свои
              контакты или свяжитесь с нами
            </p>
            <PartnersForm />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
